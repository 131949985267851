<template>
  <v-app>
    <component :is="layout">
      <slot />
    </component>
  </v-app>
</template>

<script>
import mainLayout from '@/layouts/MainLayout'

export default {
  name: 'App',
  computed: {
    layout() {
      return this.$route.meta.layout || mainLayout
    },
  },
}
</script>
