import axios from 'axios'
import apiCall from '@/utils/api'
import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/constants/consts'
import httpCodeToErr from '@/utils/http'

export const USER_STATUSES = new Map([
  [0, '-'], // TODO i18n
  [1, 'создан'], // TODO i18n
  [2, 'отправлено приглашение'], // TODO i18n
  [3, 'приглашение принято'], // TODO i18n
  [4, 'активирован'], // TODO i18n
  [5, 'дезактивирован'], // TODO i18n
])

export const USER_ROLE_ADMINISTRATOR = 1
export const USER_ROLE_MODERATOR = 2
export const USER_ROLE_MODERATOR_VK = 3
export const USER_ROLE_RESEARCHER = 4
export const USER_ROLE_ISSUER = 5

export const UserRoleEnum = {
  ADMINISTRATOR: 1,
  MODERATOR: 2,
  MODERATOR_VK: 3,
  RESEARCHER: 4,
  ISSUER: 5,
}

export const USER_ROLE_STR_ADMINISTRATOR = 'Administrator'
export const USER_ROLE_STR_MODERATOR = 'Moderator'
export const USER_ROLE_STR_MODERATOR_VK = 'Moderator VK'
export const USER_ROLE_STR_RESEARCHER = 'Researcher'
export const USER_ROLE_STR_ISSUER = 'Issuer'

export const USER_IS_AUTHENTICATED = 'USER_IS_AUTHENTICATED'
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN'
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD'
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_SEND_EMAIL_RESET_PASSWORD = 'USER_SEND_EMAIL_RESET_PASSWORD'
export const USER_CHANGE_STATUS_REQUEST = 'USER_CHANGE_STATUS_REQUEST'
// export const USER_INVITE_REQUEST = 'USER_INVITE_REQUEST'
export const IS_ADMIN = 'IS_ADMIN'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const USER_GET_PROFILE = 'USER_GET_PROFILE'
export const USER_RESTORE_TOKENS = 'USER_RESTORE_TOKENS'

const USER_SET_TOKENS = 'USER_SET_TOKENS'
const USER_UPDATE_HTTP = 'USER_UPDATE_HTTP'
const USER_SET_USER = 'USER_SET_USER'
const USER_AUTH_ERROR = 'USER_AUTH_ERROR'

var sessionRestored = false

const storageUserInfo = {
  getItem: () => {
    const storageData = localStorage.getItem('userinfo')
    if (storageData) {
      return JSON.parse(storageData)
    }
    return undefined
  },
  setItem: userInfoObj => {
    localStorage.setItem('userinfo', JSON.stringify(userInfoObj))
  },
}

const state = {
  userinfo: storageUserInfo.getItem(),
  authToken: localStorage.getItem('authToken') || undefined,
  refreshToken: localStorage.getItem('refreshToken') || undefined,
}

const getters = {
  [USER_IS_AUTHENTICATED]: (state, getters) => !!getters[GET_AUTH_TOKEN],
  [GET_AUTH_TOKEN]: state => state.authToken,
  [IS_ADMIN]: state => !!(state.userinfo?.role?.id && state.userinfo.role.id === UserRoleEnum.ADMINISTRATOR),
}

const actions = {
  async [USER_LOGIN](ctx, data) {
    let body = {
      email: data.email,
      password: data.password,
    }
    try {
      const resp = await apiCall({ url: '/api/v1/service/authentication/login', data: body, method: 'POST' })
      ctx.commit(USER_SET_TOKENS, {
        authToken: resp.headers[AUTH_TOKEN_KEY],
        refreshToken: resp.headers[REFRESH_TOKEN_KEY],
      })
      ctx.commit(USER_UPDATE_HTTP)
      return resp.data.id
    } catch (err) {
      console.error(err)
      ctx.commit(USER_AUTH_ERROR)
      const errCode = err.code || err.response.status
      throw httpCodeToErr(errCode)
    }
  },

  [USER_GET_PROFILE](ctx, uid) {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/user?uid=${uid}`, method: 'GET' })
        .then(resp => {
          ctx.commit(USER_SET_USER, resp.data)
          resolve()
        })
        .catch(err => {
          // console.log(err);
          ctx.commit(USER_AUTH_ERROR)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [USER_LOGOUT](ctx) {
    return new Promise(resolve => {
      ctx.commit(USER_LOGOUT)
      resolve()
    })
  },

  [USER_RESTORE_TOKENS](ctx) {
    return new Promise(resolve => {
      if (sessionRestored) {
        resolve()
        return
      }

      ctx.commit(USER_UPDATE_HTTP)
      resolve()
      sessionRestored = true
    })
  },

  [USER_RESET_PASSWORD](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        token: data.token || '',
        userId: data.userId || 0,
        password: data.password,
      }
      let method = data.userId > 0 ? 'PUT' : 'POST'

      apiCall({ url: '/api/v1/service/user/password-reset', data: body, method: method })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [USER_LIST_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let rolesSuffix = ``
      for (let i = 0; i < data.roles.length; i++) {
        if (i > 0) {
          rolesSuffix += '&'
        }

        rolesSuffix += `roles=${data.roles[i]}`
      }

      apiCall({ url: `/api/v1/service/users?${rolesSuffix}`, method: 'GET' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [USER_CREATE_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        email: data.email,
        name: '',
        roleId: data.roleId,
        orgId: data.orgId ?? 0,
        message: data.message,
      }

      apiCall({ url: '/api/v1/service/user', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [USER_SEND_EMAIL_RESET_PASSWORD](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        email: data.email,
      }

      apiCall({ url: `/api/v1/service/user/send-reset-email`, data: body, method: 'POST' })
        .then(() => {
          resolve()
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [USER_CHANGE_STATUS_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        userId: data.userId,
        statusId: data.statusId,
      }

      apiCall({ url: '/api/v1/service/user', data: body, method: 'PUT' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  // TODO [USER_INVITE_REQUEST] (ctx, data) {
  //   return new Promise((resolve, reject) => {
  //     let body = {
  //       userId: data.userId,
  //       message: data.message,
  //     };

  //     apiCall({url: '/api/v1/service/user', data: body, method: 'POST'})
  //     .then(resp => {
  //       resolve(resp.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       reject(httpCodeToErr(err.response.status));
  //     });
  //   })
  // },
}

const mutations = {
  [USER_SET_TOKENS](state, { authToken, refreshToken }) {
    state.authToken = authToken
    state.refreshToken = refreshToken
    localStorage.setItem('authToken', authToken)
    localStorage.setItem('refreshToken', refreshToken)
    axios.defaults.headers.common[AUTH_TOKEN_KEY] = authToken
    axios.defaults.headers.common[REFRESH_TOKEN_KEY] = refreshToken
  },

  [USER_UPDATE_HTTP](state) {
    axios.defaults.headers.common[AUTH_TOKEN_KEY] = state.authToken
    axios.defaults.headers.common[REFRESH_TOKEN_KEY] = state.refreshToken
  },

  [USER_SET_USER](state, user) {
    storageUserInfo.setItem(user)
    state.userinfo = user
  },

  [USER_AUTH_ERROR]() {},

  [USER_LOGOUT]: state => {
    state.userinfo = undefined
    state.authToken = undefined
    state.refreshToken = undefined
    localStorage.removeItem('userinfo')
    localStorage.removeItem('authToken')
    localStorage.removeItem('refreshToken')
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
