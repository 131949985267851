import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'
import { STATUS_CREATED } from '@/store/projects'
import { ProjectTypeEnum } from '@/constants/consts.js'
import i18n from '@/i18n/i18n.js'

export const PROJECT_GET_REQUEST = 'PROJECT_GET_REQUEST'
export const PROJECT_SAVE_REQUEST = 'PROJECT_SAVE_REQUEST'
export const PROJECT_GET_PRICE_REQUEST = 'PROJECT_GET_PRICE_REQUEST'
export const PROJECT_CHANGE_STATUS_REQUEST = 'PROJECT_CHANGE_STATUS_REQUEST'
export const PROJECT_STATS_REQUEST = 'PROJECT_STATS_REQUEST'
export const PROJECT_GET_FILE_REQUEST = 'PROJECT_GET_FILE_REQUEST'
export const GET_IMAGE = 'GET_IMAGE'

export const PROJECT_STATUS_ID_IS_CREATED = 'PROJECT_STATUS_ID_IS_CREATED'
export const BLOCK_TO_PREVIEW = 'BLOCK_TO_PREVIEW'
export const SET_BLOCK_TO_PREVIEW = 'SET_BLOCK_TO_PREVIEW'

const INIT_PROJECT = 'INIT_PROJECT'
const SET_PRICE = 'SET_PRICE'
export const OPEN_QUESTION_MODAL = 'OPEN_QUESTION_MODAL'
export const CLOSE_QUESTION_MODAL = 'CLOSE_QUESTION_MODAL'

const state = {
  previewedBlockId: '',
  status: '',
  statusId: STATUS_CREATED,
  priority: 0,
  targetPlatform: [],
  projectType: ProjectTypeEnum.DEFAULT,
  blocks: [],
  portraitList: [],
  priceBtnText: '',
  priceCurrency: '₽',
  questionModalProps: {
    modalFlag: false,
    block: null,
    action: 'create',
    question: null,
    questionnarie: null,
  },
}

const getters = {
  [PROJECT_STATUS_ID_IS_CREATED]: state => state.statusId === STATUS_CREATED,
  [BLOCK_TO_PREVIEW]: state => {
    const blk = []
    state.blocks.forEach(questionnaire => {
      questionnaire.blocks.forEach(block => blk.push(block))
    })
    return blk.find(block => block.id === state.previewedBlockId)
  },
}

const mutations = {
  [SET_BLOCK_TO_PREVIEW](state, blockId) {
    state.previewedBlockId = blockId
  },
  [INIT_PROJECT](state, { statusId, priority, type, blocks, portraits, targetPlatform }) {
    state.statusId = statusId
    state.priority = priority
    state.targetPlatform = targetPlatform || []
    state.projectType = type
    state.blocks = blocks && blocks.length ? JSON.parse(blocks) : []
    state.portraitList = portraits && portraits !== '' ? JSON.parse(portraits) : []
  },
  [SET_PRICE](state, { totalPrice }) {
    state.priceBtnText = `${i18n.t('pages.project.price')}: ${totalPrice} ${state.priceCurrency}`
  },
  [OPEN_QUESTION_MODAL](state, options) {
    state.questionModalProps = { ...state.questionModalProps, ...options }
    state.questionModalProps.modalFlag = true
  },
  [CLOSE_QUESTION_MODAL](state, options) {
    state.questionModalProps = { ...state.questionModalProps, ...options }
    state.questionModalProps.modalFlag = false
  },
}

const actions = {
  async [PROJECT_GET_REQUEST](ctx, data) {
    try {
      const resp = await apiCall({ url: `/api/v1/service/project?pid=${data.projectId}`, method: 'GET' })
      ctx.commit(INIT_PROJECT, resp.data)
    } catch (err) {
      throw httpCodeToErr(err.response.status)
    }
  },

  async [PROJECT_SAVE_REQUEST](ctx, data) {
    try {
      const pictures = []
      data.anketas.forEach(anketa => {
        anketa.blocks.forEach(block => {
          block.questions.forEach(question => {
            if (question.picture && typeof question.picture === 'object') {
              pictures.push(question)
            }
            question.answers.forEach(answer => {
              if (answer.picture && typeof answer.picture === 'object') {
                pictures.push(answer)
              }
            })
          })
        })
      })
      const formData = new FormData()
      pictures.forEach(pic => {
        formData.append('images', pic.picture.currentImage)
      })
      const imagesResponse = await apiCall({
        url: `/api/v1/service/picture`,
        responseType: 'multipart/form-data',
        method: 'POST',
        data: formData,
      })
      pictures.forEach(pic => {
        const { name } = pic.picture.currentImage
        pic.picture = `${process.env.VUE_APP_BASE_URL}api/v1/service/picture?pic=${imagesResponse.data.names[name]}`
      })
      let body = {
        projectId: parseInt(data.projectId),
        portraits: data.portraits,
        anketas: data.anketas,
        priority: data.priority,
      }
      if (ctx.state.targetPlatform?.length) {
        body.targetPlatform = ctx.state.targetPlatform
      }
      const resp = await apiCall({ url: '/api/v1/service/project', data: body, method: 'POST' })
      return resp.data
    } catch (err) {
      throw httpCodeToErr(err.response.status)
    }
  },

  async [PROJECT_GET_PRICE_REQUEST](ctx, projectId) {
    try {
      const resp = await apiCall({
        url: `/api/v1/service/project/price?id=${projectId}`,
        method: 'GET',
      })
      ctx.commit(SET_PRICE, resp.data)
      return resp.data
    } catch (err) {
      throw httpCodeToErr(err.response.status)
    }
  },

  async [PROJECT_CHANGE_STATUS_REQUEST](ctx, data) {
    try {
      const body = {
        projectId: parseInt(data.projectId),
        statusId: parseInt(data.statusId),
      }
      const resp = await apiCall({ url: '/api/v1/service/project/status', data: body, method: 'POST' })
      return resp.data
    } catch (err) {
      throw httpCodeToErr(err.response.status)
    }
  },

  async [PROJECT_STATS_REQUEST](ctx, data) {
    try {
      const resp = apiCall({ url: `/api/v1/service/project/stats?pid=${data.projectId}`, method: 'GET' })
      return resp.data
    } catch (err) {
      throw httpCodeToErr(err.response.status)
    }
  },

  async [PROJECT_GET_FILE_REQUEST](ctx, data) {
    try {
      const resp = await apiCall({
        url: `/api/v1/service/project/stats/file?pid=${data.projectId}&type=zip`,
        method: 'GET',
        responseType: 'blob',
      })
      return resp.data
    } catch (err) {
      throw httpCodeToErr(err.response.status)
    }
  },
  async [GET_IMAGE](_, url) {
    const imagesResponse = await apiCall({
      url: url,
      method: 'GET',
      responseType: 'blob',
    })
    return imagesResponse.data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
