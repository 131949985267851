import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import games from './games'
import cities from './cities'
import waitor from './waitor'
import history from './history'
import tariffs from './tariffs'
import issuers from './issuers'
import project from './project'
import projects from './projects'
import moderators from './moderators'
import researchers from './researchers'
import invitations from './invitations'
import organizations from './organizations'
import targetingParameters from './targeting_parameters'
import statsForVk from './stats_for_vk'
import projectConstructor from './project_constructor'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    games,
    cities,
    waitor,
    history,
    tariffs,
    issuers,
    project,
    projects,
    moderators,
    researchers,
    invitations,
    organizations,
    targetingParameters,
    statsForVk,
    projectConstructor,
  },
})
