import apiCall from '@/utils/api'
import httpCodeToErr from '@/utils/http'

export const PROJECTS_LIST_REQUEST = 'PROJECTS_LIST_REQUEST'
export const PROJECTS_SAVE_REQUEST = 'PROJECTS_SAVE_REQUEST'
export const PROJECTS_CLONE_REQUEST = 'PROJECTS_CLONE_REQUEST'
export const PROJECTS_TARGET_PLATFORMS_REQUEST = 'PROJECTS_TARGET_PLATFORMS_REQUEST'

export const STATUS_CREATED = 1
export const STATUS_ON_MODERATION = 2
export const PROJECTS_STATUSES = new Map([
  [STATUS_CREATED, 'Создан'], // TODO i18n
  [STATUS_ON_MODERATION, 'На модерации'], // TODO i18n
  [3, 'На ротации'], // TODO i18n
  [4, 'Отклонён'], // TODO i18n
  [5, 'На паузе'], // TODO i18n
  [6, 'Остановлен'], // TODO i18n
  [7, 'Завершён'], // TODO i18n
  [8, 'На модерации VK'], // TODO i18n
  // TODO add more statuses
])

const state = {
  status: '',
}

const getters = {}

const actions = {
  [PROJECTS_LIST_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      apiCall({ url: `/api/v1/service/projects?oid=${data.oid}`, method: 'GET' })
        // apiCall({url: '/api/v1/service/projects', method: 'GET'})
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [PROJECTS_SAVE_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        name: data.project.name,
        type: data.project.type,
        orgId: parseInt(data.orgId),
      }

      apiCall({ url: '/api/v1/service/projects', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  [PROJECTS_CLONE_REQUEST](ctx, data) {
    return new Promise((resolve, reject) => {
      let body = {
        projectIdToClone: parseInt(data.projectIdToClone),
        name: data.project.name,
        type: data.project.type,
        orgId: parseInt(data.orgId),
      }

      apiCall({ url: '/api/v1/service/project/clone', data: body, method: 'POST' })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          console.log(err)
          reject(httpCodeToErr(err.response.status))
        })
    })
  },

  async [PROJECTS_TARGET_PLATFORMS_REQUEST]() {
    try {
      const response = await apiCall({ url: `/api/v1/service/target-platforms`, method: 'GET' })
      return response.data
    } catch (err) {
      throw httpCodeToErr(err.response.status)
    }
  },
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
